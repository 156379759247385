import React from 'react'

const HomeCard = ({ title, body, btnText, btnUrl, btnColor }) => {

  return (
    <div className="bg-white rounded-b-md shadow-2xl p-2 relative">
      <div className="px-2 py-6 mb-16">
        <span className="text-lg font-bold text-gray-900 h-12">{title}</span>
        <div className="text-sm mt-6">
          {body}
        </div>
      </div>
      <div className='absolute bottom-2.5 left-0 w-full text-center'>
        <a href={btnUrl}
           className={`whats-new-cb-${btnColor}
              flex items-center justify-center rounded-md
              text-center font-semibold hover:shadow-xl dark:bg-gray-900 dark:border-gray-800 hover:no-underline
              w-11/12 mx-auto h-16 px-4 py-3 text-white
              cursor-pointer select-none`}>
          {btnText}
        </a>
      </div>
    </div>
  )
}


export default HomeCard
